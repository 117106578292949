import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

interface ConfirmState {
    prompt: string;
    title: string;
    isOpen: boolean;
    proceed: any;
    cancel: any;
}

type ConfirmContextType = [ConfirmState, Dispatch<SetStateAction<ConfirmState>>];

const defaultState: ConfirmState = {
    prompt: '',
    title: '',
    isOpen: false,
    proceed: null,
    cancel: null
};

const defaultSetState: Dispatch<SetStateAction<ConfirmState>> = () => {
    throw new Error('setConfirm is not initialized.');
  };

export const ConfirmContext = createContext<ConfirmContextType>([
    defaultState,
    defaultSetState])

interface Props {
    children: ReactNode;
}

const ConfirmContextProvider: React.FC<Props> = ({ children }) => {
    const [confirm, setConfirm] = useState<ConfirmState>(defaultState);

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>
    );
};
export default ConfirmContextProvider;
