import React, { useState, useRef, ChangeEvent } from 'react';
import classNames from 'classnames';
import { allCountries, Country } from 'country-telephone-data';
import { parsePhoneNumberFromString, CountryCode, PhoneNumber } from 'libphonenumber-js';
import { v4 as uuidv4 } from 'uuid';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface PhoneFieldProps {
    defaultPhoneNumber?: string;
    defaultCountryIso2?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: boolean;
    onChange?: (phoneNumber: PhoneNumber | null) => void;
    onBlur?: (phoneNumber: PhoneNumber | null) => void;
    helperText?: string;
    margin?: 'none' | 'dense' | 'normal';
    variant?: 'outlined' | 'standard';
}

const useStyles = makeStyles((theme: Theme) => ({
    phoneField: {
        width: '100%',
        minWidth: '400px',
        display: 'flex',
        alignItems: 'center',
        margin: 0
    },
    countrySelect: {},
    countryFormControl: {
        width: '100%',
        minWidth: '50px',
        maxWidth: '110px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '200px'
        }
    },
    numberFormControl: {
        flex: '1',
        marginLeft: `${theme.spacing(2)} !important`
    },
    formControl: {},
    phoneInput: {
        /* Chrome, Safari, Edge, Opera */
        '& input::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0
        },
        '& input::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0
        },
        /* Firefox */
        '& input[type=number]': {
            MozAppearance: 'textfield'
        }
    }
}));

const PhoneField = (props: PhoneFieldProps) => {
    const {
        defaultPhoneNumber,
        defaultCountryIso2,
        label = 'Phone',
        placeholder,
        required = false,
        error = false,
        onChange,
        onBlur,
        helperText,
        margin,
        variant = 'outlined'
    } = props;

    const defaultPhoneParsedNumber = defaultPhoneNumber
        ? parsePhoneNumberFromString(defaultPhoneNumber)
        : null;

    const classes = useStyles();

    const [countryCode, setCountryCode] = useState<string>(() => {
        if (defaultPhoneParsedNumber?.isValid() && defaultPhoneParsedNumber.country) {
            return defaultPhoneParsedNumber.country.toLowerCase();
        }

        return defaultCountryIso2 || 'gb';
    });
    const [phoneNumber, setPhoneNumber] = useState<any>(defaultPhoneParsedNumber?.nationalNumber);

    const countryLabel = useRef<HTMLLabelElement | null>(null);
    const countryLabelId = useRef<string>(uuidv4().toString());

    const parseNumber = (number: string, country: string) => {
        if (!number) {
            return null;
        }

        return parsePhoneNumberFromString(number, country.toUpperCase() as CountryCode);
    };

    const onChangeCountryCode = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        setCountryCode(value);
        if (onChange) {
            const parsedNumber = parseNumber(phoneNumber || '', value);
            onChange(parsedNumber || null);
        }
    };

    const onChangeNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPhoneNumber(value);

        if (onChange) {
            const parsedNumber = parseNumber(value, countryCode);
            onChange(parsedNumber || null);
        }
    };

    const onBlurCountryCode = () => {
        if (!phoneNumber) {
            return;
        }
        if (onBlur) {
            const parsedNumber = parseNumber(phoneNumber, countryCode);
            onBlur(parsedNumber || null);
        }
    };

    const onBlurNumber = () => {
        if (onBlur) {
            const parsedNumber = parseNumber(phoneNumber || '', countryCode);
            onBlur(parsedNumber || null);
        }
    };

    const handleKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
        }
    };

    return (
        (<div>
            <div className={classes.phoneField}>
                <FormControl
                    variant={variant === 'standard' ? 'standard' : 'outlined'}
                    className={classNames(classes.formControl, classes.countryFormControl)}
                    margin={margin}
                >
                    <InputLabel
                        shrink
                        ref={countryLabel}
                        htmlFor={countryLabelId.current}
                        id="country-label"
                        error={error}
                    >
                        Country
                    </InputLabel>
                    <Select
                        labelId="country-label"
                        id="country-select"
                        className={classes.countrySelect}
                        value={countryCode}
                        onChange={onChangeCountryCode}
                        onBlur={onBlurCountryCode}
                        error={error}
                        label="Country"
                    >
                        {allCountries.map((country: Country) => (
                            <MenuItem key={country.iso2} value={country.iso2}>
                                {country.name.split(' (')[0]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    className={classNames(classes.formControl, classes.numberFormControl)}
                    margin={margin}
                >
                    <TextField
                        defaultValue={phoneNumber}
                        label={label}
                        variant={variant === 'standard' ? 'standard' : 'outlined'}
                        type="number"
                        placeholder={placeholder}
                        onChange={onChangeNumber}
                        onBlur={onBlurNumber}
                        className={classNames(classes.phoneInput)}
                        onKeyDown={handleKey}
                        error={error}
                        required={required}
                        slotProps={{
                            input: {
                                'aria-label': 'phone'
                            },

                            inputLabel: { shrink: true }
                        }} />
                </FormControl>
            </div>
            {error && helperText && (
                <FormHelperText variant="outlined" error>
                    {helperText}
                </FormHelperText>
            )}
        </div>)
    );
};

export default PhoneField;
