import { firestoreInstance } from 'config/ApiService';
import { doc, getDoc } from 'firebase/firestore';
import { MEDICAL_DEVICE } from 'core/utils/collectionNames';

export const getCurrentMedicalDeviceLabel = async (): Promise<string | undefined | null> => {
    try {
        const ref = doc(firestoreInstance(), MEDICAL_DEVICE, 'versions');
        const query = await getDoc(ref);
        return query.data()?.current_version;
    } catch (e: unknown) {
        if (e instanceof Error) {
            console.log(e.message);
            return null;
        } else {
            console.log('An unknown error occurred:', e);
            return null;
        }
      
    }
};
