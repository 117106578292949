import { useEffect, useState } from 'react';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { Client, Patient, TriageRegistrationConfig } from '@eql-ai/typescript-types';
import { PATIENTS } from 'core/utils/collectionNames';
import { firestoreInstance } from 'config/ApiService';
import {
    QueryConstraint,
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    where
} from 'firebase/firestore';
import {
    Backdrop,
    Box,
    CircularProgress,
    Container,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Filter from 'views/triage/components/Filter';
import DateRangeSelector from 'views/triage/components/DateRangeSelector';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import PatientDetails from './PatientDetails';
import dayjs, { Dayjs } from 'dayjs';

export interface PatientExtended
    extends Pick<Patient, Exclude<keyof Patient, 'first_name' | 'last_name'>> {
    patient_name: string;
    registered: string;
    customer_url: string;
}
interface PatientTableProps {
    registrationConfig: TriageRegistrationConfig | null | undefined;
    client: Client;
    regConfigLoading: boolean;
}

const PatientTable = (props: PatientTableProps) => {
    const { registrationConfig, client, regConfigLoading } = props;

    const [patients, setPatients] = useState<PatientExtended[]>([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().endOf('day'));
    const [statusFilter, setStatusFilter] = useState('completed');

    const { gp_selection_mode, capture_nhs_number, capture_chi_number, custom_dropdown_title } =
        registrationConfig || {};

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const getReferralUrl = (referralUrl: string, forAgent = false) => {
        let url = referralUrl;

        if (forAgent) {
            url += '?agent=true';
        }

        return url;
    };

    const copyToClipboard = (text: string) => {
        if (text) {
            if (!navigator.clipboard) {
                enqueueSnackbar(
                    'Your current browser does not allow programmable copy and paste. Please try manually',
                    {
                        variant: 'error'
                    }
                );
            } else {
                navigator.clipboard.writeText(text).then(
                    () => {
                        enqueueSnackbar('Referral URL copied to clipboard', {
                            variant: 'success'
                        });
                    },
                    () => {
                        enqueueSnackbar('Could not copy - please try manually', {
                            variant: 'error'
                        });
                    }
                );
            }
        }
    };

    const dateFormat = (date: Dayjs) => {
        return dayjs(date,'DD/MM/YYYY HH:mm').valueOf();
    };

    const dobFormat = (date: Dayjs) => {
        return dayjs(date,'DD/MM/YYYY').valueOf();
    };

    const columns: Column<any>[] = [
        {
            field: 'id',
            title: 'UID',
            cellStyle: { minWidth: '250px' },
            emptyValue: '-'
        },
        {
            field: 'patient_name',
            title: 'Full Name',
            cellStyle: { minWidth: '250px' },
            emptyValue: '-'
        },
        {
            field: 'dob',
            title: 'DOB',
            cellStyle: { minWidth: '120px' },
            customFilterAndSearch: (term, rowData) => {
                const dateOfBirth = rowData?.dob;

                if (dateOfBirth !== 'missing') {
                    return dateOfBirth.includes(term);
                }
                return dateOfBirth.includes(term);
            },
            emptyValue: '-',
            customSort: (a, b) => {
                const date1 = dobFormat(a.dob);
                const date2 = dobFormat(b.dob);

                return Number(date2) - Number(date1);
            }
        },
        {
            field: 'registered',
            title: 'Registration Time',
            cellStyle: { minWidth: '160px' },
            emptyValue: '-',
            customSort: (a, b) => {
                const date1 = dateFormat(a.registered);
                const date2 = dateFormat(b.registered);

                return Number(date2) - Number(date1);
            }
        },
        {
            field: 'case_status',
            title: 'Case Status',
            render: (rowData) => {
                return (
                    rowData?.case_status &&
                    rowData.case_status[0]?.toUpperCase() + rowData.case_status.slice(1)
                );
            },
            cellStyle: { minWidth: '120px' },
            emptyValue: '-'
        },
        {
            field: 'short_url',
            title: 'Patient URL',
            cellStyle: { minWidth: '100px' },
            render: (rowData) => {
                return rowData.short_url ? (
                    <Tooltip title="Copy Patient Specific URL">
                        <IconButton
                            onClick={(evt) => {
                                copyToClipboard(getReferralUrl(rowData.short_url));
                                evt.stopPropagation();
                            }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    '-'
                );
            }
        },
        {
            field: 'customer_url',
            title: 'Customer URL',
            cellStyle: { minWidth: '100px' },
            render: (rowData) => {
                return rowData.short_url ? (
                    <Tooltip title="Copy Customer Specific URL">
                        <IconButton
                            onClick={(evt) => {
                                copyToClipboard(rowData.customer_url);
                                evt.stopPropagation();
                            }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    '-'
                );
            }
        },
        {
            field: 'gp_area',
            title: 'Area',
            hidden: !(
                gp_selection_mode === 'areas' ||
                gp_selection_mode === 'areas_and_gps' ||
                gp_selection_mode === 'areas_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'gp',
            title: 'GP',
            hidden: !(
                gp_selection_mode === 'areas_and_gps' ||
                gp_selection_mode === 'gps' ||
                gp_selection_mode === 'gps_text' ||
                gp_selection_mode === 'gps_text_and_gp_postcode_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'gp_postcode',
            title: 'GP Postcode',
            hidden: !(
                gp_selection_mode === 'gp_postcode_text' ||
                gp_selection_mode === 'gps_text_and_gp_postcode_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'hospital',
            title: 'Hospital',
            hidden: gp_selection_mode !== 'hospitals',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'policy_number',
            title: 'Policy Number',
            hidden: gp_selection_mode !== 'policy_number_text',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'custom_dropdown',
            title: custom_dropdown_title, // Custom dropdown
            hidden: gp_selection_mode !== 'custom_dropdown',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'nhs_number',
            title: 'NHS No.',
            hidden: !capture_nhs_number,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'chi_number',
            title: 'CHI No.',
            hidden: !capture_chi_number,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'member_id',
            title: 'Member ID',
            hidden: !client.third_party_f2f_enabled,
            cellStyle: { minWidth: '350px' },
            emptyValue: '-'
        },
        {
            field: 'policy_holder_name',
            title: 'Policy Holder Name',
            hidden: !client.third_party_f2f_enabled,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        }
    ];

    const fetchPatients = async () => {
        try {
            setLoading(true);

            const ref = collection(firestoreInstance(), PATIENTS);
            const constraints: QueryConstraint[] = [
                where('client_id', '==', client.id),
                where('created_at', '>=', startDate.toDate()),
                where('created_at', '<=', endDate.toDate()),
                orderBy('created_at', 'desc'),
                limit(500)
            ];

            if (statusFilter !== 'all') {
                constraints.unshift(where('case_status', '==', statusFilter));
            }

            const q = query(ref, ...constraints);
            const snapshots = await getDocs(q);
            let patientData: PatientExtended[] = [];

            snapshots.forEach((snapshot) => {
                const data = snapshot.data();
                const createdAt = data.created_at || data.created_on;
                patientData.push({
                    ...data,
                    id: snapshot.id,
                    patient_name: `${data.first_name} ${data.last_name}`,
                    registered: createdAt
                        ? dayjs(createdAt.toDate()).format('DD/MM/YYYY HH:mm')
                        : '-',
                    dob:
                        data?.dob && data.dob !== 'missing'
                            ? dayjs(data.dob).format('DD/MM/YYYY')
                            : 'Missing',
                    customer_url: getReferralUrl(data.short_url, true),
                    gp_area: data.gp_area === 'missing' ? '-' : data.gp_area,
                    gp: data.gp === 'missing' ? '-' : data.gp,
                    gp_postcode: data.gp_postcode === 'missing' ? '-' : data.gp_postcode,
                    hospital: data.hospital === 'missing' ? '-' : data.hospital,
                    policy_number: data.policy_number === 'missing' ? '-' : data.policy_number,
                    custom_dropdown:
                        data.custom_dropdown === 'missing' ? '-' : data.custom_dropdown,
                    nhs_number: data.nhs_number === 'missing' ? '-' : data.nhs_number
                });
            });

            setPatients(patientData);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchPatients();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client.id, endDate, startDate, statusFilter]);

    const ToolbarFilters = () => (
        <Container maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={3}>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Start Date"
                        defaultDate={startDate}
                        onDateChange={(val) => {
                            setStartDate(dayjs(val).startOf('day'));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="End Date"
                        defaultDate={endDate}
                        onDateChange={(val) => {
                            setEndDate(dayjs(val).endOf('day'));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 5, lg: 4 }}>
                    <Filter
                        label="Case Status"
                        options={[
                            { id: 'open', name: 'Open' },
                            { id: 'closed', name: 'Closed' },
                            { id: 'completed', name: 'Completed' },
                            { id: 'all', name: 'All' }
                        ]}
                        onChange={setStatusFilter}
                        fullWidth
                        value={statusFilter}
                    />
                </Grid>
            </Grid>
        </Container>
    );

    return (
        <Box>
            <MaterialTable
                title={
                    <>
                        <Typography variant="h6">Patients</Typography>
                        <Typography variant="subtitle2" color="gray">
                            Table capped to 500 results.
                        </Typography>
                    </>
                }
                data={patients}
                columns={columns}
                options={{
                    pageSize: 20,
                    pageSizeOptions: [20, 40, 60],
                    searchFieldVariant: 'outlined',
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: `${client.name} Patients - ${startDate} - ${endDate}`
                }}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            <ToolbarFilters />
                        </>
                    )
                }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit patient data',
                        onClick: (event, rowData) => {
                            navigate(`/dashboard/access/patient/${rowData.id}`);
                        }
                    }
                ]}
                detailPanel={(rowData) => {
                    return (
                        <PatientDetails
                            patient={{
                                phone: rowData?.phone || '-',
                                email: rowData?.email || '-',
                                postcode: rowData?.postcode || rowData?.post_code || '-'
                            }}
                        />
                    );
                }}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading || regConfigLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default PatientTable;
