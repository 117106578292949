import AuthActions from 'redux/actions/AuthActions';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firestoreInstance } from '../../config/ApiService';
import { CLIENT_CLINICAL_LOGS } from '../../core/utils/collectionNames';

const AuthActionCreator = {
    signIn: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({
            type: AuthActions.SIGN_IN,
            payload: {
                ...payload
            }
        });
    },
    signOut: () => (dispatch: (arg0: { type: string; }) => void) => {
        dispatch({
            type: AuthActions.SIGN_OUT
        });
    },
    setHasWeakPassword: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({ type: AuthActions.SET_HAS_WEAK_PASSWORD, payload });
    },
    setRegions: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({ type: AuthActions.SET_REGIONS, payload });
    },
    setUser: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({ type: AuthActions.SET_USER, payload });
    },
    setLocation: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({ type: AuthActions.SET_LOCATION, payload });
    },
    logForAudit: (payload: any) => async (dispatch: any, getState: () => { (): any; new(): any; AuthReducer: { organisation: any; user: any; location: any; }; }) => {
        try {
            const { organisation, user, location } = getState().AuthReducer;
            const accessPath = window.location.pathname.includes('dashboard/access/');
            const ref = collection(firestoreInstance(), CLIENT_CLINICAL_LOGS);
            await addDoc(ref, {
                ...payload,
                client_id: organisation?.id ?? null,
                executor_id: user?.id ?? null,
                executor_name: `${user.first_name} ${user.last_name}`,
                time: serverTimestamp(),
                platform: accessPath ? 'access' : 'engage',
                location
            });
        } catch (e) {
            console.log(e);
        }
    },
    setCurrentOrganisation: (payload: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
        dispatch({ type: AuthActions.SET_ORGANISATION, payload });
    }
};

export default AuthActionCreator;
