import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import AuthActionCreator from 'redux/creators/AuthActionCreators';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestoreInstance } from '../config/ApiService';
import { CLIENTS } from 'core/utils/collectionNames';
import { RootState } from 'redux/reducers';

const useStyles = makeStyles(() => ({
    root: {
        '& label.Mui-focused': {
            color: 'white'
        },
        '& .MuiFormLabel-root': {
            color: 'white'
        },
        '& .MuiTextField-root': {
            background: 'green'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white'
            },
            '&:hover fieldset': {
                borderColor: 'white'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white'
            }
        },
        width: 340
    },
    select: {
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        color: 'white'
    }
}));

export enum AGREEMENT_TYPE {
    ACCESS = 'ag_type_1',
    ENGAGE = 'ag_type_2',
    ACCESS_AND_ENGAGE = 'ag_type_3',
    ACCESS_AND_COLLECT = 'ag_type_4',
    ACCESS_ENGAGE_AND_COLLECT = 'ag_type_5',
    COLLECT = 'ag_type_6'
}

export type ClinicalServicesClient = {
    agreement_type: AGREEMENT_TYPE;
    name: string;
    id: string;
};

const ClinicalServicesDropdown = () => {
    const classes = useStyles();

    const customer = useSelector((state: RootState) => state.AuthReducer.organisation);

    const [customers, setCustomers] = React.useState<Array<ClinicalServicesClient>>([]);

    const [selectedCustomer, selectCustomer] = React.useState<ClinicalServicesClient | undefined>(
        undefined
    );

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const val = event.target.value;
            const currentCustomer = customers.find((cst) => cst.id === val);
            if (currentCustomer) {
                selectCustomer(currentCustomer);
                dispatch(
                    AuthActionCreator.setCurrentOrganisation({
                        ...currentCustomer,
                        name: currentCustomer.name,
                        id: currentCustomer.id
                    })
                );
            }
        },
        // eslint-disable-next-line
        [customers]
    );

    React.useEffect(() => {
        (async () => {
            const ref = query(
                collection(firestoreInstance(), CLIENTS),
                where('agreement_type', 'in', [
                    AGREEMENT_TYPE.ENGAGE,
                    AGREEMENT_TYPE.ACCESS_AND_ENGAGE,
                    AGREEMENT_TYPE.ACCESS_ENGAGE_AND_COLLECT
                ]),
                where('clinical_services_enabled', '==', true)
            );
            const res = await getDocs(ref);
            const temp: ClinicalServicesClient[] = [];
            res.forEach((doc) => {
                const data: any = { ...doc.data(), id: doc.id };
                temp.push({
                    ...data,
                    agreement_type: data.agreement_type,
                    name: data.name,
                    id: data.id
                });
            });
            setCustomers(temp);
        })();
    }, []);

    React.useEffect(() => {
        if (!customer || !customers.length) {
            return;
        }
        const currentCustomer = customers.find((cst) => cst.id === customer.id);
        if (currentCustomer) {
            selectCustomer({ ...currentCustomer });
            enqueueSnackbar(
                `You are currently providing clinical services for ${currentCustomer.name}`,
                {
                    variant: 'info'
                }
            );
        }
        // eslint-disable-next-line
    }, [customer, customers]);

    return (<>
        <TextField
            label="Customer"
            variant="outlined"
            select
            size="small"
            className={classes.root}
            onChange={handleChange}
            value={selectedCustomer?.id || ''}
            sx={{ marginRight: 2 }}
            slotProps={{
                input: {
                    margin: 'dense',
                    style: { color: 'white' }
                },

                select: { className: classes.select }
            }}>
            {[{ agreement_type: '', name: '', id: '' }]
                .concat(customers)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                        {customer.name}
                    </MenuItem>
                ))}
        </TextField>
    </>);
};

export default ClinicalServicesDropdown;
