import { isFunction, isNil, isString } from 'lodash';
import Papa, { ParseConfig } from 'papaparse';

export interface FieldSchema {
    name: string;
    inputName: string;
    optional?: boolean;
    required?: boolean;
    isArray?: boolean;
    headerError?: (
        headerValue: string,
        headerName: string,
        rowNumber: number,
        columnNumber: number
    ) => string;
    requiredError?: (headerName: string, rowNumber: number, columnNumber: number) => string;
    validate?: (
        field: string | number | boolean,
        headerName: string,
        rowNumber: number,
        columnNumber: number
    ) => string;
    dependentValidate?: (
        field: string,
        row: string[],
        headerName: string,
        rowNumber: number,
        columnNumber: number
    ) => string;
}

interface ValidatorConfig {
    headers: FieldSchema[];
    parserConfig?: ParseConfig;
}

interface RowError {
    rowIndex?: number;
    columnIndex?: number;
    message: string;
}

interface ParsedResults<Row = any, Error = RowError> {
    /** Array of parsed CSV entries */
    data: Row[];

    /** List of validation errors data */
    inValidData: Error[];
}

function getClearRow(row: any[]) {
    return row.map((columnValue: any) => clearValue(columnValue));
}

function clearValue(value: string) {
    return isString(value) ? value.replace(/^\ufeff/g, '') : value;
}

const isEmpty = (value: string) => {
    if (isString(value)) {
        return !!!value.trim().length;
    }

    return isNil(value);
};

function prepareDataAndValidateFile(csvData: any[], config: ValidatorConfig) {
    const file = {
        inValidData: [],
        data: []
    } as ParsedResults;

    csvData.forEach((row: any[], rowIndex: number) => {
        const columnData = {} as any;

        // fields are mismatch
        if (rowIndex !== 0 && row.length !== config.headers.length) {
            file.inValidData.push({
                rowIndex,
                message: `Number of fields mismatch: expected ${config.headers.length} fields but parsed ${row.length}. In the row ${rowIndex}`
            });
        }

        // check if the header matches with a config
        if (rowIndex === 0 && row.length !== config.headers.length) {
            config.headers.forEach(function (header: { name: string }, headerIndex: number) {
                if (header.name !== row[headerIndex]) {
                    file.inValidData.push({
                        message: `Header name ${header.name} is not correct or missing`
                    });
                }
            });
        }

        row.forEach((columnValue: string, index: number) => {
            const valueConfig = config.headers[index];
            const columnIndex = index + 1;

            columnValue = clearValue(columnValue);

            if (!valueConfig) {
                return;
            }

            // header validation
            if (rowIndex === 0) {
                if (valueConfig.name !== columnValue) {
                    file.inValidData.push({
                        rowIndex: rowIndex + 1,
                        columnIndex: columnIndex,
                        message: isFunction(valueConfig.headerError)
                            ? valueConfig.headerError(
                                  columnValue,
                                  valueConfig.name,
                                  rowIndex + 1,
                                  columnIndex
                              )
                            : `Header name ${columnValue} is not correct or missing in the ${
                                  rowIndex + 1
                              } row / ${columnIndex} column. The Header name should be ${
                                  valueConfig.name
                              }`
                    });
                }
            }

            if (rowIndex !== 0) {
                if (valueConfig.required && isEmpty(columnValue)) {
                    file.inValidData.push({
                        rowIndex: rowIndex + 1,
                        columnIndex: columnIndex,
                        message: isFunction(valueConfig.requiredError)
                            ? valueConfig.requiredError(valueConfig.name, rowIndex + 1, columnIndex)
                            : `${valueConfig.name} is required in the ${
                                  rowIndex + 1
                              } row / ${columnIndex} column`
                    });
                } else if (
                    valueConfig.validate &&
                    !!valueConfig.validate(columnValue, valueConfig.name, rowIndex + 1, columnIndex)
                ) {
                    file.inValidData.push({
                        rowIndex: rowIndex + 1,
                        columnIndex: columnIndex,
                        message: isFunction(valueConfig.validate)
                            ? valueConfig.validate(
                                  columnValue,
                                  valueConfig.name,
                                  rowIndex + 1,
                                  columnIndex
                              )
                            : `${valueConfig.name} is not valid in the ${
                                  rowIndex + 1
                              } row / ${columnIndex} column`
                    });
                } else if (
                    valueConfig.dependentValidate &&
                    !!valueConfig.dependentValidate(
                        columnValue,
                        getClearRow(row),
                        valueConfig.name,
                        rowIndex + 1,
                        columnIndex
                    )
                ) {
                    file.inValidData.push({
                        rowIndex: rowIndex + 1,
                        columnIndex: columnIndex,
                        message: isFunction(valueConfig.dependentValidate)
                            ? valueConfig.dependentValidate(
                                  columnValue,
                                  getClearRow(row),
                                  valueConfig.name,
                                  rowIndex + 1,
                                  columnIndex
                              )
                            : `${valueConfig.name} not passed dependent validation in the ${
                                  rowIndex + 1
                              } row / ${columnIndex + 1} column`
                    });
                }
                if (valueConfig.optional) {
                    columnData[valueConfig.inputName] = columnValue;
                }

                if (valueConfig.isArray) {
                    columnData[valueConfig.inputName] = columnValue
                        .split(',')
                        .map((value: string) => (isString(value) ? value.trim() : value));
                } else {
                    columnData[valueConfig.inputName] = columnValue;
                }
            }
        });

        if (Object.keys(columnData).length) {
            file.data.push(columnData);
        }
    });

    return file;
}

const CSVFileValidator = (csvFile: any, config: ValidatorConfig) => {
    return new Promise(function (resolve, reject) {
        if (!config || (config && !config.headers)) {
            return resolve({
                inValidData: [{ message: 'config headers are required' }],
                data: []
            });
        }

        Papa.parse(csvFile, {
            ...config.parserConfig,
            skipEmptyLines: true,
            worker: true,
            complete: function (results) {
                resolve(prepareDataAndValidateFile(results.data, config));
            }
        });
    });
};

export default CSVFileValidator;
