import AuthActions from 'redux/actions/AuthActions';

export const initialAuthState = {
    isSignedIn: false,
    accessLevel: null,
    isAdmin: false,
    hasWeakPassword: false,
    user: {
        email: null,
        name: null
    },
    organisation: null,
    location: {
        latitude: 0.0,
        longitude: 0.0
    },
    permissions: [],
    regions: null,
    databaseId: null,
    currentRegion: null
};

const addToLocalStorage = (value: string) => {
    window.localStorage.setItem('fid', value);
};
const clearLocalStorage = () => {
    window.localStorage.removeItem('fid');
};
export const AuthReducer = (state = initialAuthState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case AuthActions.SIGN_IN: {
            // This is a hack to store the databaseId in local storage
            addToLocalStorage(payload.databaseId);
            return {
                ...state,
                isSignedIn: true,
                accessLevel: payload.accessLevel,
                user: payload.user,
                isAdmin: payload.isAdmin,
                organisation: payload.organisation,
                permissions: payload.permissions,
                regions: payload.regions,
                databaseId: payload.databaseId,
                currentRegion: payload.region
            };
        }
        case AuthActions.SIGN_OUT: {
            // hack to clear the databaseId from local storage
            clearLocalStorage();
            return {
                ...state,
                isSignedIn: false,
                accessLevel: null,
                user: {
                    email: null,
                    name: null
                },
                isAdmin: false,
                organisation: null,
                location: null,
                permissions: [],
                regions: null,
                databaseId: null,
                currentRegion: null
            };
        }
        case AuthActions.SET_HAS_WEAK_PASSWORD: {
            return {
                ...state,
                hasWeakPassword: payload
            };
        }
        case AuthActions.SET_REGIONS: {
            return {
                ...state,
                regions: payload
            };
        }
        case AuthActions.SET_USER: {
            return {
                ...state,
                user: {
                    ...payload
                }
            };
        }
        case AuthActions.SET_ORGANISATION: {
            return {
                ...state,
                organisation: payload
            };
        }
        case AuthActions.SET_LOCATION: {
            return {
                ...state,
                location: {
                    ...payload
                }
            };
        }
        default: {
            return state;
        }
    }
};
