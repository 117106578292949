import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface DateRangeSelectorProps {
    fullWidth?: boolean;
    label?: string;
    defaultDate?: any;
    onDateChange?: (date: Dayjs) => void;
    error?: {
        message?: string;
    };
    required?: boolean;
    inputFormat?: string;
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const { label, onDateChange, required, inputFormat, fullWidth, error, defaultDate } = props;

    const [value, setValue] = useState<Dayjs | null>(defaultDate ? (defaultDate as any) : dayjs());
    const [validDateError, setValidDateError] = useState<string>('');

    const handleChange = (newValue: Dayjs | null) => {
        if (!newValue) {
            return;
        }

        const isValidDate =
            dayjs(newValue).isValid() &&
            dayjs(newValue).isAfter(dayjs(new Date(1900, 0, 1))) &&
            dayjs(newValue).isBefore(dayjs().add(10, 'year'));

        if (!isValidDate) {
            setValidDateError('Invalid date');
            return;
        }
        setValidDateError('');
        setValue(newValue);
        if (onDateChange && newValue) {
            onDateChange(newValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label || 'Date Picker'}
                format={inputFormat || 'DD/MM/YYYY'}
                onChange={handleChange}
                value={value}
                slotProps={{
                    textField: {
                        helperText: error?.message || validDateError,
                        error: error || validDateError ? true : false,
                        required: required,
                        fullWidth: fullWidth
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DateRangeSelector;
