import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import { firestoreInstance } from '../../config/ApiService';
import { SESSION_TRACKER } from '../../core/utils/collectionNames';
import dayjs from 'dayjs';

const CompletedSessionsToday = () => {
    const [count, setCount] = React.useState<number>(0);
    React.useEffect(() => {
        const refDate = new Date();
        const baseDate = new Date(
            refDate.getFullYear(),
            refDate.getMonth(),
            refDate.getDate(),
            0,
            0,
            0
        );
        const fromDate = dayjs(baseDate).startOf('day');
        const toDate = dayjs(fromDate).add(24, 'hour');
        const ref = collectionGroup(firestoreInstance(), SESSION_TRACKER);
        const q = query(
            ref,
            where('submitted_at', '>=', fromDate.toDate()),
            where('submitted_at', '<=', toDate.toDate())
        );
        getDocs(q).then((snapshots) => {
            // console.log('data', snapshots.docs.length);
            setCount(snapshots.docs.length);
        });
    }, []);

    return (
        <div>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                NUMBER OF COMPLETED SESSIONS TODAY
            </Typography>
            <Typography component="p" variant="h4">
                {count}
            </Typography>
            <Divider />
            <Box></Box>
        </div>
    );
};

export default CompletedSessionsToday;
