import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import type { Theme } from '@mui/material/styles';
import { Paper, Card, CardContent, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { red, blue } from '@mui/material/colors';
import classNames from 'classnames';
import DeviceIcon from '@mui/icons-material/PhoneIphone';
import AndroidIcon from '@mui/icons-material/PhoneAndroidRounded';
import CaseCountTile from './GodModeComponents/CaseCountTile';
import GenericPieChart from './GodModeComponents/GenericPieChart';
import { CardHeader } from '@mui/material';
import SkippedToday from './GodModeComponents/SkippedToday';
import CompletedSessionsToday from './GodModeComponents/CompletedSessionsToday';
import CustomerComponent from './GodModeComponents/CustomerComponent';
import {
    collection,
    doc,
    DocumentSnapshot,
    getDocs,
    onSnapshot,
    QuerySnapshot,
    updateDoc
} from 'firebase/firestore';
import { firestoreInstance } from '../config/ApiService';
import { INJURY_MANAGEMENT_PROGRAMMES, TREATMENT_PLANS } from '../core/utils/collectionNames';
// import MaterialTable from 'material-table';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(1),
                width: 240
                // height: theme.spacing(16)
            }
        },
        fixedHeight: {
            margin: theme.spacing(1),
            height: 240
        },
        cardHeight: {
            margin: theme.spacing(1),
            height: 380
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column'
        },
        avatar: {
            backgroundColor: red[500]
        }
    })
);

const GodView = () => {
    const [engagePatients, setEngagePatients] = React.useState<any[]>([]);
    const [versionLabels, setVersionLabels] = React.useState<string[]>([]);
    const [versionSeries, setVersionSeries] = React.useState<number[]>([]);
    // const [cleanData, setCleanData] = React.useState<any[]>([]);
    const classes = useStyles();

    const fixedHeightPaper = classNames(classes.paper, classes.fixedHeight);
    const fixedCardHeight = classNames(classes.paper, classes.cardHeight);

    const getClosedCases = React.useCallback(() => {
        const closureCodes = [
            'Discharge - Onward Referral',
            'Discharge - Non Compliant',
            'Discharge - Failed Capture',
            'Discharge - Other'
        ];
        if (engagePatients.length) {
            const c = engagePatients.filter((t, y) => closureCodes.includes(t.case_status_code));
            return c.length;
        }
        return 0;
    }, [engagePatients]);

    const getOpenCases = React.useCallback(() => {
        if (engagePatients.length) {
            const completionCodes = ['Discharge - Programme Complete', 'Discharge - Recovered'];
            const c = engagePatients.filter((t, i) => completionCodes.includes(t.case_status_code));
            return c.length;
        }
        return 0;
    }, [engagePatients]);

    React.useEffect(() => {
        const handleSnapshot = (snapshots: QuerySnapshot) => {
            const temp: unknown[] = [];
            snapshots.forEach((doc: DocumentSnapshot) => {
                temp.push({ ...doc.data(), id: doc.id });
            });
            setEngagePatients(temp);
        };
        const ref = collection(firestoreInstance(), TREATMENT_PLANS);
        const unsubscribe = onSnapshot(ref, handleSnapshot);
        return () => {
            unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        if (!engagePatients.length) {
            return;
        }
        const versions = engagePatients.map((p) => p.app_version).filter((v) => v !== undefined);
        const uniqueVersions = [...new Set(versions)];
        const versionSeries: number[] = [];
        for (let uniqueVersion of uniqueVersions) {
            versionSeries.push(versions.filter((v) => v === uniqueVersion).length);
        }
        setVersionLabels([...uniqueVersions]);
        setVersionSeries([...versionSeries]);
    }, [engagePatients]);

    // React.useEffect(() => {
    //     (async () => {
    //         const ref = firebase
    //             .firestore()
    //             .collection('treatment_plans')
    //             .where('client_name', '==', 'Circle Health');
    //         const res = await ref.get();
    //         const t: any[] = [];
    //         res.forEach((doc) => {
    //             t.push({ ...doc.data() });
    //         });
    //         console.log('t', t);
    //         const clean = t.map((d) => ({
    //             name: `${d.first_name} ${d.last_name}`,
    //             phone: String(d.phone),
    //             email: d.email,
    //             dob: d.dob,
    //             programme: d.programme,
    //             id: d.id,
    //             status: d.case_status || '',
    //             onboarded: d.onboarded ? 'yes' : 'no',
    //             stage: d.current_stage || ''
    //         }));
    //         console.log('clean', clean);
    //         setCleanData(clean);
    //     })();
    // }, []);

    // return (
    //     <MaterialTable
    //         title="Medicash Engage Patients"
    //         data={cleanData}
    //         columns={[
    //             { title: 'UID', field: 'id' },
    //             { title: 'Name', field: 'name' },
    //             { title: 'Phone', field: 'phone' },
    //             { title: 'Email', field: 'email' },
    //             { title: 'Programme', field: 'programme' },
    //             { title: 'Stage', field: 'stage' },
    //             { title: 'status', field: 'status' },
    //             { title: 'Onboarded', field: 'onboarded' }
    //         ]}
    //         options={{ exportAllData: true, exportButton: true }}
    //     />
    // );

    // React.useEffect(() => {
    //     (async () => {
    //         const ref = firebase.firestore().collection('treatment_plans');
    //         const res = await ref.get();
    //         const temp: any[] = [];
    //         res.forEach((doc) => {
    //             temp.push({ ...doc.data(), id: doc.id });
    //         });
    //         const filtered = temp.filter(
    //             (tp) =>
    //                 tp.case_status === 'active' &&
    //                 tp.onboarded === true &&
    //                 tp.is_onboarding === undefined
    //         );
    //         console.log('filtered', filtered);
    //         if (!filtered.length) {
    //             return;
    //         }
    //         const promises: any[] = [];
    //         filtered.forEach((item) => {
    //             promises.push(
    //                 firebase
    //                     .firestore()
    //                     .collection('treatment_plans')
    //                     .doc(item.id)
    //                     .update({ is_onboarding: true })
    //             );
    //         });
    //         await Promise.all(promises);
    //         console.log('in app onboarding update completed...');
    //     })();
    // }, []);

    React.useEffect(() => {
        (async () => {
            const ref = collection(firestoreInstance(), INJURY_MANAGEMENT_PROGRAMMES);
            const temp: any[] = [];
            const res = await getDocs(ref);
            res.forEach((doc) => {
                temp.push({ ...doc.data(), id: doc.id });
            });
            console.log('programmes', temp);
            const promises: any[] = [];
            const hasNoStatus = temp.filter((t) => t.is_active === undefined);
            if (!hasNoStatus.length) {
                return;
            }
            hasNoStatus.forEach((t) => {
                promises.push(
                    updateDoc(doc(firestoreInstance(), INJURY_MANAGEMENT_PROGRAMMES, t.id), {
                        is_active: true
                    })
                );
            });
            await Promise.all(promises);
            console.log('update done');
        })();
    }, []);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid size={4}>
                    <Paper className={fixedHeightPaper}>
                        <CompletedSessionsToday />
                    </Paper>
                </Grid>
                <Grid size={4}>
                    <Paper className={fixedHeightPaper}>
                        <SkippedToday />
                    </Paper>
                </Grid>
                <Grid size={4}>
                    <Paper className={fixedHeightPaper}>
                        <CaseCountTile
                            total={engagePatients.length}
                            closed={getClosedCases()}
                            completed={getOpenCases()}
                            waiting={
                                engagePatients.filter(
                                    (p) => p.onboarded === false && p.case_status === 'active'
                                ).length
                            }
                            onboarded={
                                engagePatients.filter(
                                    (p) => p.onboarded === true && p.case_status === 'active'
                                ).length
                            }
                        />
                    </Paper>
                </Grid>
                <Grid size={6}>
                    <Card className={fixedCardHeight}>
                        <CardHeader
                            avatar={
                                <Avatar style={{ backgroundColor: blue[500] }}>
                                    <DeviceIcon />
                                </Avatar>
                            }
                            title="Platform Split"
                            subheader="Mobile Operating System of patients"
                        />
                        <CardContent>
                            <GenericPieChart
                                series={[
                                    engagePatients.filter((p, i) => p.platform === 'Android')
                                        .length,
                                    engagePatients.filter((p, i) => p.platform === 'iOS').length
                                ]}
                                labels={['Android', 'iOS']}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={4}>
                    <Card className={fixedCardHeight}>
                        <CardHeader
                            avatar={
                                <Avatar className={classes.avatar}>
                                    <AndroidIcon />
                                </Avatar>
                            }
                            title="Version Split"
                            subheader="Versions of Phio Engage"
                        />
                        <CardContent>
                            <GenericPieChart series={versionSeries} labels={versionLabels} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={12}>
                    <CustomerComponent />
                </Grid>
            </Grid>
        </div>
    );
};

export default GodView;
