import Papa from 'papaparse';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

const templateData = {
    fields: [
        'Patient First Name',
        'Patient Last Name',
        'Date of Birth',
        'Email Address',
        'Mobile Phone Number',
        'Postcode',
        'Area',
        'GP',
        'GP Postcode',
        'Hospital',
        'Policy Number',
        'Policy Holder Name',
        'NHS No',
        'CHI No'
    ],
    data: [
        [
            '( Required )',
            '( Required )',
            'YYYY-MM-DD ( Required )',
            '( Required ) Email or Phone number is required',
            '( Required ) Email or Phone number is required',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )',
            '( Optional )'
        ]
    ]
};

const downloadCsvTemplate = () => {
    const csv = Papa.unparse(templateData);
    const nav: Navigator = navigator;

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if (nav.msSaveBlob) {
        csvURL = nav.msSaveBlob(csvData, 'patients_csv_template.csv');
    } else {
        csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement('a');
    tempLink.href = csvURL as string;
    tempLink.setAttribute('download', 'patients_csv_template.csv');
    tempLink.click();
};

export default downloadCsvTemplate;
