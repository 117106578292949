import dayjs from 'dayjs';
import AppActions from 'redux/actions/AppActions';

const initialAppState = {
    client: null,
    patientData: [],
    env: 'dev',
    triageData: [],
    weeklyAssessment: [],
    dailyAssessment: [],
    skippedSessionData: [],
    completedSessionData: [],
    triageDateFilterFrom: dayjs(new Date()).startOf('day'),
    triageDateFilterTo: dayjs(new Date()).endOf('day'),
    triageFilterStatus: 'end',
    patientFilter: 'all',
    statusFilter: 'active',
    programmeInEdit: null
};

export const AppReducer = (state = initialAppState, action: { type: any; payload: any; }) => {
    const { type, payload } = action;
    switch (type) {
        case AppActions.SET_CLIENT:
            return {
                ...state,
                client: payload
            };
        case AppActions.SET_PATIENT_DATA:
            return {
                ...state,
                patientData: payload
            };
        case AppActions.SET_SESSION_DATA:
            return {
                ...state,
                completedSessionData: payload
            };
        case AppActions.SET_SKIPPED_DATA:
            return {
                ...state,
                skippedSessionData: payload
            };
        case AppActions.SET_WEEKLY_ASSESSMENT:
            return {
                ...state,
                weeklyAssessment: payload
            };
        case AppActions.SET_DAILY_ASSESSMENT:
            return {
                ...state,
                dailyAssessment: payload
            };
        case AppActions.SET_ENV:
            return {
                ...state,
                env: payload
            };
        case AppActions.SET_TRIAGE_DATA:
            return {
                ...state,
                triageData: payload
            };
        case AppActions.SET_TRIAGE_DATE_FILTER_FROM: {
            return {
                ...state,
                triageDateFilterFrom: payload
            };
        }
        case AppActions.SET_TRIAGE_DATE_FILTER_TO: {
            return {
                ...state,
                triageDateFilterTo: payload
            };
        }
        case AppActions.SET_TRIAGE_FILTER_STATUS: {
            return {
                ...state,
                triageFilterStatus: payload
            };
        }
        case AppActions.SET_PATIENT_FILTER: {
            return {
                ...state,
                patientFilter: payload
            };
        }
        case AppActions.SET_STATUS_FILTER: {
            return {
                ...state,
                statusFilter: payload
            };
        }
        case AppActions.SET_PROGRAMME_IN_EDIT: {
            return {
                ...state,
                programmeInEdit: payload
            };
        }
        case AppActions.CLEAR_PROGRAMME_IN_EDIT: {
            return {
                ...state,
                programmeInEdit: null
            };
        }
        default:
            return state;
    }
};
